@font-face {
  font-family: 'LibreBarcode128Text-Regular';
  src: local('LibreBarcode128Text-Regular'), url('./fonts/LibreBarcode128Text-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans-Bold';
  src: local('OpenSans-Bold'), url('./fonts/OpenSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans-Regular';
  src: local('OpenSans-Regular'), url('./fonts/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans-SemiBold';
  src: local('OpenSans-SemiBold'), url('./fonts/OpenSans-SemiBold.ttf') format('truetype');
}

:root {
  // colors
  --blue: #006091;
  --blue-light: #a3dbff;
  --red: #9a2a2a;
  --red-light: #ff9898;
  --red-error: #d32f2f;
  --green: #267631;
  --green-light: #8ce88d;
  --yellow: #ffc107;
  --yellow-dark: #9a7702;
  --purple: #834dc1;
  --purple-light: #6352A8;
  --white: #ffffff;
  --black: #000000;

  // Fonts
  --font-black: #000000;
  --gray-dark: #242424;

  // Element
  --default-background: #6352A8;
  --widget-background: #F9F9F9;
  /*
  --enhance-color: #77d1ff;
  --navigation-background: #50504f;
  --navigation-color: #ffffff;
  --logo-color: #77d1ff;
  --section-top-background: #666665;
  --section-body-background: #e8e8e7;
   */

  // Client styles
}

body {
  font-family: OpenSans-SemiBold, OpenSans-Regular, OpenSans-Bold, sans-serif;
  font-size: 10px !important;
  background-color: #ECECEB;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-width: 320px;
}


/*
:root {
  --black: #000000;
  --white: #ffffff;
  --gray-light: #f9f9f9;
  --blue: #00537D;
  --purple: #322844;
  --purple-light: #6352A8;
  --cyan: #B6FFF0;
  --green: #186A00;
  --yellow: #FFBD08;
}

 */
/*
html, body {
  height: 100%;
  font-size: 10px;
}

#root {
  width: 100%;
  min-width: 320px;
  display: flex;
  // flex-direction: column;
  box-sizing: border-box;
  height: 100vh;
}
 */
